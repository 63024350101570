<div class="buttonAreaHistory">
    <button type="submit" (click)="refreshHistory()"
    class="btn btn-danger refresh">Refresh History</button>
</div>
<div class="history-table mb-4">
    <div class="tableBorder">
        <div class="history-table-responsive">
            <div *ngIf="showHistory" class="hist-loader">
                <div calss="hist-loader-name">Loading...</div>
                <div class="hist-loader-icon"></div>
            </div>
            <div *ngIf="accountHistory?.length">
                <table class="table table-hover history-table" aria-describedby="Account History">
                    <thead>
                        <tr class="tca">
                            <th>Date Verified</th>
                            <th>Bank ID</th>
                            <th>Account Number</th>
                            <th>Beneficiary Name</th>
                            <th>Name Match</th>
                            <th>Account Status</th>
                            <th>Inquiry Status</th>
                            <th>Inquired By</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of accountHistory?.slice(currentPageIndex * pageSize, (currentPageIndex + 1) * pageSize); let i = index;">
                            <td class="text-center"><span>{{item.createdDate | date: 'MM/dd/yyyy'}}</span></td>
                            <td class="text-center"><span>{{item.bankId}}</span></td>
                            <td class="text-center"><span>{{item.bankAccountNumber}}</span></td>
                            <td class="text-center"><span>{{ getBeneficiaryName(item) }}</span></td>
                            <td class="text-center">
                                <div class="status-container" *ngIf="item.inquiryStatus.toUpperCase() !== 'INITIATED'">
                                    <div>
                                        {{getDisplayName(item)}}
                                    </div>
                                    <span class="sso-tooltip status-icon" *ngIf="getDisplayName(item) === 'N/A'" >
                                        <div class="acc-verif-icon icon-info"></div>
                                        <span id="question" class="tooltipText loginTooltip">
                                            <span class="hoverText">{{naTooltipText}}
                                                <span id="tail">
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            
                            </td>
                            <td class="text-center">
                                <div style="display: table-cell;">
                                    <div class="status-container">
                                        <div class="acc-verif-icon" [ngClass]="'icon-' + (item.accountStatus | lowercase)"></div>
                                        <div style="padding-left: 5px;">{{item.accountStatus | sentenceCase }}</div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center" style="display: table-cell;">
                                <div class="status-container">
                                    <div class="status-text">{{item.inquiryStatus | sentenceCase }}</div>

                                    <span class="sso-tooltip status-icon">
                                        <div class="acc-verif-icon icon-info"></div>
                                        <span id="question" class="tooltipText loginTooltip">
                                            <span class="hoverText">{{item.inquiryStatusMessage}}
                                                <span id="tail">
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td class="text-center"><span>{{item.userProfile?.firstName}} {{item.userProfile?.lastName}}</span></td>
                        </tr>
                    </tbody>
                </table>
                <div class="history-table-pagination">
                    <mat-paginator *ngIf="showPagination" [length]="collectionSize" [pageSizeOptions]="pageSizeOptions" [pageSize]="10"
                    (page)="tablePagination($event)"></mat-paginator>
                </div>
            </div>
            <div *ngIf="noHistoryRecords"> 
                **No Account Verification History**
            </div>
        </div>
    </div>
</div>