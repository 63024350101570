import { Component, OnInit, ViewChild } from '@angular/core';
import { singleSpaPropsSubject } from 'src/single-spa/single-spa-props';
import { DynamicComp } from './directives/dynamic-comp.directive';
import { AccountVerificationComponent } from './account-verification/account-verification.component';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-account-verification-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'account-verification';
  currentCompName: string = "";
  customClass: string = "";
  shouldDisplayTheComps: boolean = true;
  @ViewChild(DynamicComp, { static: true }) compRef!: DynamicComp;

  compHash: { name: string, compRef: any }[] = [
    { name: "account-verification", compRef: AccountVerificationComponent }
  ];

  ngOnInit() {

    // datadog integration
    datadogRum.init({
      applicationId: 'd01627fd-3482-42f7-ab68-b8ead3e63c02',
      clientToken: 'pub1629f01f4765ddac1a5a2cb1bc800d08',
      site: 'datadoghq.com',
      service: 'sso-client-',
      env: environment.env,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
    datadogLogs.init({
      clientToken: 'pub1629f01f4765ddac1a5a2cb1bc800d08',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      env: environment.env,
      service: 'account-verification-mfe',
      forwardConsoleLogs: ['error']
    })
    singleSpaPropsSubject.subscribe((props: any) => {
      console.log(props);
    });
  }

  injectComponent() {
    if (!this.currentCompName) return;

    const componentObj = this.compHash.find(comp => comp.name === this.currentCompName);
    if (!componentObj) return;

    const viewRef = this.compRef.viewContainerRef;
    viewRef.clear();
    viewRef.createComponent(componentObj.compRef);
  }
}