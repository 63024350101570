import { Component, OnInit } from '@angular/core';
import { assetURL, environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VerificationViewComponent } from './verification-view/verification-view.component';
import { ApiService } from '../services/api.service';
import { AccountVerificationTransmitService } from '../services/transmit.service';

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.scss']
})
export class AccountVerificationComponent implements OnInit {

  credData: any;
  universalId: any;
  showValidateAccountAction: boolean = false;
  showValidationHistoryAction: boolean = false;
  isMobileDevice: boolean = false;

  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private transmitService: AccountVerificationTransmitService) {
  }

  ngOnInit(): void {
    this.isMobileDevice = this.apiService.isMobileDevice();
    // check for creds
    this.credData = sessionStorage.getItem('credData')
    this.universalId = sessionStorage.getItem('universalId')

    const aoCompanyList = JSON.parse(this.credData).results.filter((item: any) => item.applicationName === 'accessoptima').map((item: any) => item.compId);
    if (aoCompanyList.length > 0) {
      this.apiService.checkBillingAccounts(aoCompanyList).subscribe(data => {
        if (data && data.length) {
          this.showValidateAccountAction = true;
        }
      })
    }
  }

  get assetURL() {
    return assetURL;
  }

  openModal(val: string) {
    this.apiService.setCurrentTab(val);
    const modalRef = this.modalService.open(VerificationViewComponent, { fullscreen: true, windowClass: 'fullscreen-modal' });
    // modalRef.componentInstance.isHistoryDisabled = !this.showValidationHistoryAction;
  }


}
