import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { assetURL, environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { BehaviorSubject, catchError, debounceTime, filter, of, switchMap, map, Observable } from 'rxjs';
import { AccountVerificationUtils } from 'src/app/services/account-verification.utils';
import { AccountVerificationThreatMetrixService } from 'src/app/services/account-verification-tmx.service';
import { AccountVerificationTransmitService } from 'src/app/services/transmit.service';
import { datadogLogs } from '@datadog/browser-logs';

@Component({
  selector: 'app-account-validation',
  templateUrl: './account-validation.component.html',
  styleUrls: ['./account-validation.component.scss'],
  providers: [MaskAccountPipe]
})
export class AccountValidationComponent implements OnInit, AfterViewInit {

  public validationForm: UntypedFormGroup;
  public submitted = false;
  public loading = false;
  public bankIdTypes: string[] = [];

  statusMessages: { [key: string]: string } = {
    INITIATED: 'This account verification is in process.  Please see below for the details of recent inquiry.',
    COMPLETED: 'This account has been validated previously. Please see below for the result of most recent inquiry.',
    TERMINATED: `Account Verification Service is Unavailable – Your request cannot be processed at this time.  \
                If the problem persists, please click <a href="${environment.acc_verif_universalLoginCase}" target="\_blank\">here</a>\
                to open a Service Case, or contact Client Services \
                at <strong>877-550-5933 (401-282-1362)</strong> or email us at \
                <a href="mailto:clientservices@mail.client.citizensbank.com">clientservices@mail.client.citizensbank.com</a>, \
                24 hours a day, 7 days a week.`,
    BADREQUEST: `There was an error processing your request. \
                Please contact Client Services to open a case and allow us to investigate further. \
                Client Services can be reached at <strong>877-550-5933 (401-282-1362)</strong> or via email at \
                <a href="mailto:clientservices@mail.client.citizensbank.com">clientservices@mail.client.citizensbank.com</a>, 24 hours a day, 7 days a week.`
  };

  public bankAccountTypes: string[] = [];
  public isBrazil: boolean = false;
  public credentials;
  public companyIds: Map<string, string[]> = new Map();
  public billingAccountList: Map<string, [string, string]> = new Map();
  public selectedBillingAccount;
  public maskedAccountNum;
  public termsAndConditions: string = '';
  public accountNum;
  public isChecked = false;
  private modalRef: NgbModalRef;
  public validatedInfo: any;
  accValidationForm: boolean = true;
  validationMsg: string;
  accAlreadyVerified: boolean;
  private validateAccountRequestBody: any;
  private validateAccountRequestHeaders: { universalId: string | null; companyId: string | undefined; userId: string | undefined; getStatusIfExists: string; };
  private reValidateAccountRequestHeaders: any;
  validationPendingMsg: boolean;
  @ViewChild('termsContent') termsContent: ElementRef;
  mergedAck: any;
  errorModal: boolean;
  lastBankId = '';
  bankName = '';
  errorMessage: string | null = null;
  tmxProfiling: boolean = false;
  profiledUrl = environment.acc_verif_tmxProfiledUrlPrefix + '/sso/dashboard';
  private tmxUnivSessionId: string;
  unMaskedAccountNum: string;
  disableForm: boolean = false;
  shouldStatusToFalse: boolean = false;
  hideCaret: boolean = false;
  aoCompanyIds: string[] = [];

  private isEligibleForAcctNameInquirySubject = new BehaviorSubject<boolean>(false);
  isEligibleForAcctNameInquiry$ = this.isEligibleForAcctNameInquirySubject.asObservable();

  naTooltipText: String = 'This name was not able to be verified by the Responding Financial Institution.'

  bankIdTypeNew: string[] = [];
  selectedBankIdType: string;
  selectedCountryData: any;
  isBranchIdRequired: any;
  isBankIdDisabled: any;
  bankId: any;
  enableTaxId: any;
  showNationalId: any;
  formValues: any;

  countryList: any;
  allData: any[];
  showBankId: boolean;
  currentBeneficiary: any;
  showBeneficiaryType = false;

  constructor(private formBuilder: FormBuilder,
    private modal: NgbModal,
    private service: ApiService,
    private alertService: AlertService,
    private maskAccountPipe: MaskAccountPipe,
    private spinnerService: SpinnerService,
    private utils: AccountVerificationUtils,
    private threatMetrixService: AccountVerificationThreatMetrixService,
    private transmitService: AccountVerificationTransmitService) { }

  get f() {
    return this.validationForm.controls;
  }

  get assetURL() {
    return assetURL;
  }

  get checkBeneficiaryNameMatch() {
    return (this.validatedInfo?.beneficiaryIndividualName?.trim() || this.validatedInfo?.businessName?.trim()) ?
              (this.validatedInfo?.beneficiaryNameMatch ? this.validatedInfo?.beneficiaryNameMatch : 'N/A') : '';
  }

  ngOnInit(): void {
    const ack = sessionStorage.getItem('ack');
    if (ack) {
      this.mergedAck = JSON.parse(ack)
    }
    this.createForm();
    this.service.getCountryDetailsFromCDN().subscribe(data => {
      this.allData = data;
      this.countryList = data.map((item: any) => ({
        name: item.countryName,
        code: item.code
      }))
      this.onCountryChange();
    })
    this.service.getTermsAndConditionsFromCDN().subscribe((res) => {
      this.termsAndConditions = res;
    })
    const credStore = sessionStorage.getItem('credData');
    
    if (credStore) {
      this.credentials = JSON.parse(credStore).results ?? [];
    }

    this.credentials.filter((item) => item.applicationName.toLowerCase() === 'accessoptima').forEach((item) => {
      const userId = item.userId.toUpperCase();
      const compId = item.compId.toUpperCase()
      
      if (!this.companyIds.has(userId)) {
        this.companyIds.set(userId, []);
      }

      this.companyIds.get(userId)!.push(compId)
      this.aoCompanyIds.push(compId);
    });

    const compId = Array.from(this.companyIds.values()).flat();
    this.service.billingAccountList(compId).subscribe({
      next: (res) => {
        res.forEach((item) => {
          this.accountNum = item.billingAccountNum
          this.billingAccountList.set(this.accountNum, [item.billingBankCd, item.businessCifId]);
          if (this.billingAccountList.size === 1) {
            this.selectedBillingAccount = this.billingAccountList.keys().next().value;
            this.unMaskedAccountNum = this.selectedBillingAccount;
            this.maskedAccountNum = this.maskAccountPipe.transform(this.selectedBillingAccount);
          }
        })
      },
      error: (error) => {
        console.error(error);
        const universalId = sessionStorage.getItem('universalId');
        datadogLogs.logger.error(`operation=Billing Account Details; universalId=${universalId}; msgtxt=${error}`);
      }
    })

    this.getBankNameForUSABA();
    this.tmxUnivSessionId = this.loadTmxUnivLoginServ();
    this.service.getProfilingFlagFromCDN().subscribe(res => {
      this.tmxProfiling = res.profilingData;
    })

  }

  ngAfterViewInit(): void {
    this.updateBankAccountNumberValidators(this.validationForm.get('bankAccountType')?.value);
    this.validationForm.get('bankAccountType')?.valueChanges.subscribe((value) => {
      this.updateBankAccountNumberValidators(value);
    });

    this.validationForm.get('bankIdType')?.valueChanges.subscribe((value) => {
      this.updateBankIdValidators(value);
    });

    this.validationForm.get('beneficiaryType')?.valueChanges.subscribe((value) => {
      this.updateTaxIDValidators(value);
      this.validationForm.get('beneficiaryFirstName')?.setValue('');
      this.validationForm.get('beneficiaryLastName')?.setValue('');
      this.validationForm.get('businessName')?.setValue('');
    });

    if (this.bankIdTypes.length === 1) {
      this.updateBankIdValidators(this.validationForm.get('bankIdType')?.value);
    }

    if (this.tmxProfiling) {
      this.transmitService.initializeTransmit(environment.acc_verif_transmitUrl).subscribe((res) => {
        console.log('transmit initialize');
      });
    }
  }

  createForm(): void {
    this.validationForm = this.formBuilder.group({
      country: ['US'],
      bankIdType: ['United States'],
      bankId: ['', Validators.required],
      bankAccountType: ['', Validators.required],
      bankAccountNumber: ['', Validators.required],
      taxId: [''],
      branchId: [''],
      nationalId: [''],
      beneficiaryType: ['individual'],
      businessName: ['', [Validators.maxLength(150)]],
      beneficiaryFirstName: ['', [Validators.maxLength(35)]],
      beneficiaryLastName: ['', [Validators.maxLength(35)]],
      billingAccount: ['', Validators.required],
      terms: [false, Validators.requiredTrue]
    }, { validators: this.minLengthCombinedValidator(3) });
  }

  loadTmxUnivLoginServ(): string {
    const tmxUnivSessId = this.threatMetrixService.initThreatMetrixService('Universal');
    this.threatMetrixService.addEnhancedProfilingScriptTag(tmxUnivSessId);
    return tmxUnivSessId;
  }

  getBankNameForUSABA() {
    const universalId = sessionStorage.getItem('universalId');
    this.validationForm.get('bankId')?.valueChanges.pipe(
      debounceTime(300),
      filter(value => {
        const bankIdType = this.validationForm.get('bankIdType')?.value;
        return bankIdType === 'USABA' && value.length === 9 && !value.includes(' ') && /^[0-9]{9}$/.test(value)
      }),
      switchMap(value => {
        this.lastBankId = value;
        return this.service.postRoutingNumber(value, universalId)
          .pipe(
            catchError((error) => {
              console.error('error in api', error);
              this.errorMessage = this.service.errorMessage;
              this.disableForm = true;
              this.isEligibleForAcctNameInquirySubject.next(false);
              return of(null);
            })
          );
      })
    ).subscribe({
      next: (bankId) => {
        if (bankId) {
          this.errorMessage = null;
          const currentBankId = this.validationForm.get('bankId')?.value;
          this.bankName = bankId?.data?.bankName;
          this.isEligibleForAcctNameInquirySubject.next(bankId?.data?.isEligibleForAcctNameInquiry);
          this.validationForm.get('bankId')?.setValue(`${currentBankId} ${this.bankName}`, { emitEvent: false });
          this.validationForm.get('bankId')?.clearValidators();
          this.validationForm.get('bankId')?.updateValueAndValidity();
        } else {
          this.isEligibleForAcctNameInquirySubject.next(false);
        }
      },
      error: (error) => {
        console.error('err in api', error);
        const universalId = sessionStorage.getItem('universalId');
        datadogLogs.logger.error(`operation=GetBankNameForUSABA; universalId=${universalId}; country= ${this.validationForm.get('country')?.value}; msgtxt=${error}`);
      }
    });

    this.validationForm.get('bankId')?.valueChanges.pipe(
      filter(value => value.split(' ')[0].length !== 9 && value.includes(' '))
    ).subscribe({
      next: () => {
        const currentBankId = this.validationForm.get('bankId')?.value.split(' ')[0];
        this.validationForm.get('bankId')?.setValue(currentBankId + '', { emitEvent: false });
        this.validationForm.get('bankId')?.setValidators([Validators.required, Validators.pattern(/^[0-9]{9}$/)]);
        this.validationForm.get('bankId')?.updateValueAndValidity();
      },
      error: (error) => {
        console.error('err in api', error);
        const universalId = sessionStorage.getItem('universalId');
        datadogLogs.logger.error(`operation=GetBankNameForUSABA; universalId=${universalId}; country= ${this.validationForm.get('country')?.value}; msgtxt=${error}`);
      }
    });


    this.validationForm.get('bankId')?.valueChanges.pipe(
      filter(value => !value.includes(this.bankName) && value.length !== 9)
    ).subscribe({
      next: () => {
        const currentBankId = this.validationForm.get('bankId')?.value;
        this.validationForm.get('bankId')?.setValue(currentBankId + '', { emitEvent: false });
        this.validationForm.get('bankId')?.setValidators([Validators.required, Validators.pattern(/^[0-9]{9}$/)]);
        this.validationForm.get('bankId')?.updateValueAndValidity();
        this.isEligibleForAcctNameInquirySubject.next(false);
      },
      error: (error) => {
        console.error('err in api', error);
        const universalId = sessionStorage.getItem('universalId');
        datadogLogs.logger.error(`operation=GetBankNameForUSABA; universalId=${universalId}; country= ${this.validationForm.get('country')?.value}; msgtxt=${error}`);
      }
    });
  }

  updateBankIdValidators(type) {
    const bankIdControl = this.validationForm.get('bankId');

    switch (type) {
      case 'USABA':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^[0-9]{9}$/)]);
        break;
      case 'IBAN':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^[0-9A-Za-z-]{5,34}$/)]);
        break;
      case 'CLABE':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^[0-9]{18}$/)]);
        break;
      case 'IFSC':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9]{11}$/)]);
        break;
      case 'SWIFT ID':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^([a-zA-Z0-9]{8}|[a-zA-Z0-9]{11})$/)]);
        break;
      case 'Brazil Bank Code':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^[0-9]{3}$/)]);
        break;
      case 'CNAPS':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^\d{12,14}$/)]);
        break;
      case 'CACPA':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^0002\d{5}$/)]);
        break;
      case 'CCI':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^\d{20}$/)]);
        break;
      case 'CBU':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^\d{22}$/)]);
        break;
      case 'CVU':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^\d{22}$/)]);
        break;
      case 'Account Number':
        bankIdControl?.setValidators([Validators.required, Validators.pattern(/^(?![ .-])[a-zA-Z0-9](?:[a-zA-Z0-9 .-]{0,34}[a-zA-Z0-9])?$/)]);
        break;
      default:
        bankIdControl?.setValidators(Validators.required);
    }
    bankIdControl?.updateValueAndValidity();
  }

  updateBankAccountNumberValidators(type) {
    const bankAccountNumberControl = this.validationForm.get('bankAccountNumber');

    switch (type) {
      case 'Account Number':
        bankAccountNumberControl?.setValidators([Validators.required, Validators.pattern(/^(?![ .-])[a-zA-Z0-9](?:[a-zA-Z0-9 .-]{0,34}[a-zA-Z0-9])?$/)]);
        break;
      case 'IBAN':
        bankAccountNumberControl?.setValidators([Validators.required, Validators.pattern(/^[0-9A-Za-z-]{5,34}$/)]);
        break;
      case 'CLABE':
        bankAccountNumberControl?.setValidators([Validators.required, Validators.pattern(/^[0-9]{18}$/)]);
        break;
      case 'BBAN':
        bankAccountNumberControl?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9]{10,30}$/)]);
        break;
      case 'CBU':
        bankAccountNumberControl?.setValidators([Validators.required, Validators.pattern(/^\d{22}$/)]);
        break;
      case 'CVU':
        bankAccountNumberControl?.setValidators([Validators.required, Validators.pattern(/^\d{22}$/)]);
        break;
      default:
        bankAccountNumberControl?.setValidators(Validators.required);
    }
    bankAccountNumberControl?.updateValueAndValidity();
  }

  minLengthCombinedValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstName = control.get('beneficiaryFirstName')?.value || '';
      const middleName = control.get('beneficiaryMiddleName')?.value || '';
      const lastName = control.get('beneficiaryLastName')?.value || '';
      const totalLength = firstName.length + middleName.length + lastName.length;
      if (!firstName && !middleName && !lastName) {
        return null;
      }
      return totalLength >= minLength ? null : { minLengthCombined: true };
    };
  }

  updateTaxIDValidators(type) {
    const taxIdControl = this.validationForm.get('taxId');
    if (this.enableTaxId && (type === 'CNAPS' || type === 'SWIFT ID')) {
      taxIdControl?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9]{18}$/)])
    } else {
      this.validationForm.get('taxId')?.setValue('');
      this.enableTaxId = false;
      taxIdControl?.setValidators([])
    }
    taxIdControl?.updateValueAndValidity();
  }

  onCountryChange(): void {
    this.updateValidationValuesOnCountryChange();
    const selectedCountry = this.validationForm.get('country')?.value;
    this.selectedCountryData = this.allData.find((country) => country.code === selectedCountry);
    const bankIdField = this.selectedCountryData?.fields.find((field: any) => field.name === 'bankIdType');
    this.showNationalId = this.selectedCountryData?.requiresNationalId || null;
    this.showBeneficiaryType = this.selectedCountryData?.showBeneficiaryType ? this.selectedCountryData?.showBeneficiaryType : false
    if (this.showNationalId) {
      this.validationForm.get('nationalId')?.enable();
      this.validationForm.get('nationalId')?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9/.,-]{1,35}$/)]);
    } else {
      this.validationForm.get('nationalId')?.disable();
      this.validationForm.get('nationalId')?.clearValidators();
    }
    this.validationForm.get('nationalId')?.updateValueAndValidity();
    
    if (bankIdField) {
      this.bankIdTypes = Object.keys(bankIdField.mapping) || [];
    }

    this.resetDefaultValues(false);

    if (this.bankIdTypes.length === 1) {
      this.validationForm.get('bankIdType')?.setValue(this.bankIdTypes[0]);
      this.selectedBankIdType = this.bankIdTypes[0];
      this.validationForm.get('bankIdType')?.disable();
      this.updateBankIdValidators(this.bankIdTypes[0]);
      this.bankAccountTypes = this.selectedBankIdType ? bankIdField?.mapping[this.selectedBankIdType]?.bankAccountType || [] : [];
      if (this.bankAccountTypes?.length === 1) {
        this.validationForm.get('bankAccountType')?.setValue(this.bankAccountTypes[0]);
        this.validationForm.get('bankAccountType')?.disable();
      } else {
        this.validationForm.get('bankAccountType')?.enable();
        this.validationForm.get('bankAccountType')?.setValue('');
      }
    } else {
      this.validationForm.get('bankIdType')?.enable();
      this.validationForm.get('bankIdType')?.setValue('');
    }
  }

  get checkForBeneficiaryType() {
    return this.validationForm.get('beneficiaryType')?.value;
  }

  onBankIdTypeChange(): void {
    this.updateValidationValuesOnBankIDTypeChange()
    const selectedBankIdType = this.validationForm.get('bankIdType')?.value;
    const bankIdField = this.selectedCountryData?.fields.find(field => field?.name === 'bankIdType')
    this.showBeneficiaryType = this.selectedCountryData?.showBeneficiaryType;
    if (bankIdField) {
      const selectedMapping = bankIdField?.mapping[selectedBankIdType]
      this.bankAccountTypes = selectedMapping?.bankAccountType || [];
      this.isBranchIdRequired = selectedMapping?.requiresBranchId || false;
      if (this.isBranchIdRequired) {
        this.validationForm.get('branchId')?.enable();
        this.validationForm.get('branchId')?.setValidators([Validators.required, Validators.pattern(/^[0-9-]{1,5}$/)]);
      } else {
        this.validationForm.get('branchId')?.disable();
        this.validationForm.get('branchId')?.clearValidators();
      }
      this.validationForm.get('branchId')?.updateValueAndValidity();
      if (selectedMapping.bankId) {
        this.showBankId = true;
        this.bankId = selectedMapping.bankId;
        this.isBankIdDisabled = selectedMapping.disableBankId || null;
        if (this.isBankIdDisabled) {
          this.validationForm.get('bankId')?.disable();
        }

      } else {
        this.showBankId = false;
      }

      if (bankIdField.enableTaxId) {
        this.enableTaxId = bankIdField.enableTaxId;
        this.validationForm.get('taxId')?.enable();
        this.validationForm.get('taxId')?.setValue('');
        this.updateTaxIDValidators(this.validationForm.get('bankIdType')?.value)
      } else {
        this.enableTaxId = false;
        this.validationForm.get('taxId')?.disable();
        this.validationForm.get('taxId')?.setValue('');
      }
      if (this.bankAccountTypes?.length === 1) {
        this.validationForm.get('bankAccountType')?.setValue(this.bankAccountTypes[0]);
        this.validationForm.get('bankAccountType')?.disable();
        this.updateBankAccountNumberValidators(this.bankAccountTypes[0]);
      } else {
        this.validationForm.get('bankAccountType')?.enable();
        this.validationForm.get('bankAccountType')?.setValue('');
      }
    }
  }

  // step to reset custom assignments before country change
  updateValidationValuesOnCountryChange() {
    this.showBeneficiaryType = false;
    this.validationForm.get('beneficiaryType')?.setValue('individual');
    this.validationForm.get('bankIdType')?.setValue('');
    this.validationForm.get('bankId')?.enable();
    this.validationForm.get('bankAccountType')?.setValue('');
    this.validationForm.get('bankAccountType')?.disable();
    this.validationForm.get('branchId')?.disable();
    this.validationForm.get('nationalId')?.disable();
    this.isBranchIdRequired = false;
    this.enableTaxId = false;
    this.showBankId = false;
    this.bankId = '';
  }

  // step to reset custom assignments before bankid type change
  updateValidationValuesOnBankIDTypeChange() {
    this.validationForm.get('bankId')?.enable();
    this.validationForm.get('bankId')?.setValue('');
    this.validationForm.get('bankAccountNumber')?.setValue('');
    this.validationForm.get('taxId')?.setValue('');
    this.bankId = '';
  }

  resetDefaultValues(resetCountry: boolean) {
    if (resetCountry) {
      this.validationForm.get('country')?.setValue('');
      this.validationForm.get('bankAccountType')?.setValue('');
      this.validationForm.get('bankAccountType')?.enable();
      this.validationForm.get('bankIdType')?.setValue('');
      this.validationForm.get('bankIdType')?.enable();
    }

    this.validationForm.get('bankIdType')?.setValue('');
    this.validationForm.get('bankId')?.setValue('');
    this.errorMessage = '';
    this.validationForm.get('bankAccountNumber')?.setValue('');
    this.validationForm.get('terms')?.setValue('');
    this.validationForm.get('beneficiaryFirstName')?.setValue('');
    this.validationForm.get('beneficiaryLastName')?.setValue('');
    this.validationForm.get('businessName')?.setValue('');
    if (this.billingAccountList.size > 1) {
      this.validationForm.get('billingAccount')?.setValue('');
    }
    if (this.validationForm.get('nationalId')) {
      this.validationForm.get('nationalId')?.setValue('');
    }
    if (this.validationForm.get('branchId')) {
      this.validationForm.get('branchId')?.setValue('');
    }
    if (this.validationForm.get('taxId')) {
      this.validationForm.get('taxId')?.setValue('');
    }
  }

  onBack(): void {
    this.modal.dismissAll();
  }

  openTerms(content: any) {
    this.modalRef = this.modal.open(content, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
  }

  onSubmit(): void {
    this.spinnerService.setText('Please wait...')
    this.spinnerService.showSpinner();
    if (!this.validationForm.valid) {
      console.error('form is invalid');
    } else {
      if (this.tmxProfiling) {
        const universalId = sessionStorage.getItem('universalId');
        const profilingData = this.postAccountValidationDetails().body;
        this.transmitService.authenticate(universalId, profilingData, 'validate_account', this.tmxUnivSessionId, this.profiledUrl)
          .then((res) => {
            this.postAccountValidationDetails();
            const headers = this.postAccountValidationDetails().headers;
            const body = this.postAccountValidationDetails().body
            const revalidateHeaders = this.postAccountValidationDetails().revalidateHeaders;
            this.validateAccountRequestBody = body;
            this.validateAccountRequestHeaders = headers;
            this.reValidateAccountRequestHeaders = revalidateHeaders;
            this.accountValidation(headers, body);
          }).catch((error) => {
            this.spinnerService.hideSpinner();
            console.error('transmit error', error);
            // log details in datadog incase of issue
            const universalId = sessionStorage.getItem('universalId');
            datadogLogs.logger.error(`operation=GetBankNameForUSABA; universalId=${universalId}; country= ${this.validationForm.get('country')?.value}; msgtxt=${error}`);
            if (this.getNested(error, '_data', 'failure_data', 'reason', 'data', 'status', 'error', 'numcode') === '3203') {
              this.alertService.error('There was an error processing your request, please contact client services at 1-877-550-5933 for more information');
            }
          })
      } else {
        this.postAccountValidationDetails();
        const headers = this.postAccountValidationDetails().headers;
        const body = this.postAccountValidationDetails().body;
        const revalidateHeaders = this.postAccountValidationDetails().revalidateHeaders;
        this.validateAccountRequestBody = body;
        this.validateAccountRequestHeaders = headers;
        this.reValidateAccountRequestHeaders = revalidateHeaders;
        this.accountValidation(headers, body)
      }
    }
    this.formValues = {}; //resetting the formValues
  }

  newValidationRequest() {
    this.resetDefaultValues(true);
    this.validationPendingMsg = false;
    this.accValidationForm = true;
  }

  onBackFromPendingModal() {
    this.validationPendingMsg = false;
    this.modal.dismissAll();
  }

  revalidateRequest() {
    this.spinnerService.setText('Please wait...')
    this.spinnerService.showSpinner();
    this.shouldStatusToFalse = true;
    this.accountValidation(this.reValidateAccountRequestHeaders, this.validateAccountRequestBody)
  }

  accountValidation(headers, body) {
    this.validationPendingMsg = false;
    this.errorModal = false;
    this.service.postAccountValidation(headers, body, this.aoCompanyIds).subscribe({
      next: (res) => {
        // check if account is pre-validated
        let status = res.data.accountVerificationStatus;
        if (status) {
          this.validationMsg = this.statusMessages[status?.inquiryStatus] || 'Unknown status. Please check again or contact support.';
          switch (status?.inquiryStatus) {
            case 'INITIATED':
              this.accAlreadyVerified = true;
              this.accValidationForm = false;
              this.errorModal = false;
              break;
            case 'COMPLETED':
              this.accAlreadyVerified = true;
              this.accValidationForm = false;
              this.errorModal = false;
              break;
            case 'TERMINATED':
              if (status?.closureMessage.startsWith('400')) {
                this.errorModal = true;
                this.accAlreadyVerified = false;
                this.accValidationForm = true;
                this.validationMsg = this.statusMessages['BADREQUEST'];
              } else {
                this.accAlreadyVerified = false;
                this.accValidationForm = true;
                this.errorModal = true;
              }
              break;
          }
          this.validatedInfo = res?.data?.accountVerificationStatus;
          this.spinnerService.hideSpinner();
        } else {
          let refId = res?.data?.inquiryIdentifier?.referenceId;
          const universalId = sessionStorage.getItem('universalId');
          // check account validation after 10 secs wait time...
          setTimeout(() => {
            this.service.inquireByRefId(refId, universalId).subscribe({
              next: res => {
                this.spinnerService.hideSpinner();
                switch (res?.data?.inquiryStatus) {
                  case 'INITIATED':
                    this.validationPendingMsg = true;
                    break;
                  case 'COMPLETED':
                    this.validationMsg = 'Account Validation completed successfully.'
                    this.accValidationForm = false;
                    this.validatedInfo = res.data;
                    break;
                  case 'UNVERIFIED':
                  case 'REJECTED':
                  case 'INCORRECT':
                    this.validationMsg = res?.data?.inquiryStatusMessage;
                    this.accValidationForm = false;
                    this.validatedInfo = res.data;
                    break;
                  case 'TERMINATED':
                    const closureMessage = JSON.parse(res?.data.closureMessage);
                    const systemStatusMessage = closureMessage?.systemStatusMessage;
                    const errorCode = systemStatusMessage.split(/[-]/)[0] || '';
                    this.errorModal = true;
                    this.accAlreadyVerified = false;
                    this.accValidationForm = true;
                    if (closureMessage?.systemStatusCode.startsWith('50')) {
                      this.validationMsg = this.statusMessages['TERMINATED'] + `(Error Code: ${errorCode})`;
                    } else {
                      this.validationMsg = this.statusMessages['BADREQUEST'] + `(Error Code: ${errorCode})`;
                    }
                    break;
                }
              },
              error: (error) => {
                this.spinnerService.hideSpinner();
                if (error.name === 'TimeoutError') {
                  this.validationPendingMsg = true;
                }
                const universalId = sessionStorage.getItem('universalId');
                datadogLogs.logger.error(`operation=GetBankNameForUSABA; universalId=${universalId}; country= ${this.validationForm.get('country')?.value}; msgtxt=${error}`);
              }
            })
          }, 10000)
        }
      },
      error: (error) => {
        this.spinnerService.hideSpinner();
        console.error(error);
        const universalId = sessionStorage.getItem('universalId');
        datadogLogs.logger.error(`operation=GetBankNameForUSABA; universalId=${universalId}; country= ${this.validationForm.get('country')?.value}; msgtxt=${error}`);

        this.errorModal = true;
        this.accAlreadyVerified = false;
        this.accValidationForm = true;
        this.validationMsg = this.statusMessages['TERMINATED'];
      }
    })

  }

  bankIdKeyUp() {
    const bankIdType = this.validationForm.get('bankIdType')?.value;
    if (bankIdType && bankIdType == 'SWIFT ID') {
      this.updateBankIdValidators(bankIdType);
    }
  }

  getNested(obj: any, ...args: any) {
    return args.reduce((obj: any, level: any) => obj && obj[level], obj);
  }

  postAccountValidationDetails() {
    const rawValues = this.validationForm.getRawValue();

    this.formValues = { ...rawValues }
    if (this.bankId) {
      this.formValues.bankId = this.bankId;
    }


    let inquiryIdentifier: any;
    if (this.billingAccountList.size === 1) {
      inquiryIdentifier = {
        "billingAccount": this.unMaskedAccountNum,
        "billingBank": this.billingAccountList.get(this.unMaskedAccountNum)?.[0]
      }
    } else {
      inquiryIdentifier = {
        "billingAccount": this.validationForm.value.billingAccount,
        "billingBank": this.billingAccountList.get(this.validationForm.value.billingAccount)?.[0]
      }
    }

    const body = {
      "accountIdentifier": {
        "country": this.formValues.country,
        "lastName": this.validationForm.value.beneficiaryLastName.trim() || undefined,
        "firstName": this.validationForm.value.beneficiaryFirstName.trim() || undefined,
        "accountType": this.utils.replaceSpacesWithUnderScore(this.formValues.bankAccountType),
        "businessName": this.validationForm.value.businessName.trim() || undefined,
        "accountNumber": this.utils.removeHyphen(this.formValues.bankAccountNumber).trim(),
        "nationalId": this.validationForm.value.taxId ?
          this.validationForm.value.taxId : (this.validationForm.value.nationalId ?
          this.validationForm.value.nationalId.trim() : undefined),

      },
      "bankIdentifier": {
        "idType": this.utils.replaceSpacesWithUnderScore(this.formValues.bankIdType),
        "idValue": this.formValues.bankId.split(' ')[0],
        "branchId": this.validationForm.value.branchId ? this.validationForm.value.branchId.trim() : undefined
      },
      "inquiryIdentifier": inquiryIdentifier
    };

    let compId: any;
    if (this.billingAccountList.size === 1) {
      compId = this.billingAccountList.get(this.unMaskedAccountNum)?.[1]
    } else {
      compId = this.billingAccountList.get(this.validationForm.value.billingAccount)?.[1]
    }

    const headers = {
      universalId: sessionStorage.getItem('universalId'),
      companyId: compId,
      userId: this.getKeyByValue(compId, this.companyIds),
      getStatusIfExists: 'true'
    };

    const revalidateHeaders = {
      universalId: sessionStorage.getItem('universalId'),
      companyId: compId,
      userId: this.getKeyByValue(compId, this.companyIds),
      getStatusIfExists: 'false'
    }

    return { body, headers, revalidateHeaders };
  }

  onTermsClick(event: any) {
    this.isChecked = event.target.checked;
    const universalId = sessionStorage.getItem('universalId');
    const formattedDate = moment().tz('America/New_York').utc().format('YYYY-MM-DD HH:mm:ss');
    if (this.isChecked) {
      this.service.saveTermsValue(formattedDate, universalId, this.mergedAck).subscribe({
        next: (res) => {
          console.log('Terms');
        },
        error: (err) => {
          console.error(err);
        }
      })
    }
  }

  getKeyByValue(searchValue, map: Map<string, string[]>) {
    for (let [key, value] of map) {
      if (value.includes(searchValue)) {
        return key;
      }
    }
  }

  onContinue() {
    this.closeModal();
  }

  trimValue(value: string): string {
    return value ? value.trim() : '';
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

}
