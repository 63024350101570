(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@citizens/mfe-shared-state"), require("rxjs"));
	else if(typeof define === 'function' && define.amd)
		define(["@citizens/mfe-shared-state", "rxjs"], factory);
	else if(typeof exports === 'object')
		exports["comm-sso-account-verification-mfe"] = factory(require("@citizens/mfe-shared-state"), require("rxjs"));
	else
		root["comm-sso-account-verification-mfe"] = factory(root["@citizens/mfe-shared-state"], root["rxjs"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__79322__, __WEBPACK_EXTERNAL_MODULE__63961__) => {
return 