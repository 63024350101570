<div>
    <div class="container" *ngIf="accValidationForm">
        <div class="row">
            <div class="col-12">
                <app-alert></app-alert>
            </div>
            <div class="account-validation">
                <form [formGroup]="validationForm">
                    <!-- Country of Account -->
                    <div class="form-group full-width">
                        <label for="country">Country of Account
                            <div class="sso-info-tooltip-country question-circle-country">
                                <span class="tooltip-text info-tooltip"><span class="hover-text">Choose country of bank
                                        account you would like to validate.
                                        <span id="tooltip-tail"></span>
                                    </span></span>
                            </div>
                        </label>
                        <select id="country" class="form-control" formControlName="country"
                            (change)="onCountryChange()">
                            <option *ngFor="let country of countryList" [value]="country.code">{{country.name}}
                            </option>
                        </select>
                    </div>

                    <!-- Bank ID Type -->
                    <div class="form-group" *ngIf="bankIdTypes.length > 1">
                        <label for="bank-id-type">Bank ID Type</label>
                        <select id="bank-id-type" class="form-control" formControlName="bankIdType"
                            (change)="onBankIdTypeChange()">
                            <option value="" disabled></option>
                            <option *ngFor="let idType of bankIdTypes" [value]="idType">{{idType}}</option>
                        </select>
                    </div>
                    <div class="form-group bank-id" *ngIf="bankIdTypes.length === 1">
                        <label for="bank-id-type">Bank ID Type</label>
                        <input type="text" id="bank-id-type" class="form-control" formControlName="bankIdType"
                            [value]="bankIdTypes[0]" disabled>
                    </div>

                    <!-- Bank ID -->
                    <div class="form-group bank-id">
                        <label for="bank-id">Bank ID
                            <div class="sso-info-tooltip-bank-id question-circle-bank-id">
                                <span class="tooltip-text info-tooltip"><span class="hover-text">SWIFT/BIC or Local
                                        routing code for International account. ABA/Routing Number for US accounts.
                                        <span id="tooltip-tail"></span>
                                    </span></span>
                            </div>
                        </label>
                        <input *ngIf="!showBankId" type="text" id="bank-id" class="form-control" maxlength="50" autocomplete="off"
                            formControlName="bankId" [value]="trimValue(validationForm.get('bankId')?.value)" (keyup)="bankIdKeyUp()">
                        <input *ngIf="showBankId" type="text" id="bank-id" class="form-control" [value]="bankId" disabled>

                        <div class="text-danger" *ngIf="errorMessage" [innerHTML]="errorMessage">
                        </div>

                        <div class="text-danger"
                            *ngIf="validationForm.get('bankId')?.errors?.pattern && validationForm.get('bankIdType')?.value === 'USABA'">
                            USABA value may only contain numbers, and must be 9 characters in length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.get('bankId')?.errors?.pattern && validationForm.get('bankIdType')?.value === 'CLABE'">
                            CLABE value may only contain numbers, and must be 18 characters in length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.get('bankId')?.errors?.pattern && validationForm.get('bankIdType')?.value === 'IFSC'">
                            IFSC value may only contain letters and numbers, and must be 11 characters in length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.get('bankId')?.errors?.pattern && validationForm.get('bankIdType')?.value === 'SWIFT ID'">
                            SWIFT ID value may only contain letters and numbers, and must be 8 or 11 characters in
                            length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.get('bankId')?.errors?.pattern && validationForm.get('bankIdType')?.value === 'IBAN'">
                            IBAN value may only contain letters, numbers, and hyphens(-); and must be between 5 and 34
                            characters in length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.controls['bankId']?.errors?.pattern && validationForm.get('bankIdType')?.value === 'Brazil Bank Code'">
                            Brazil Bank Code value may only contain numbers, and must be 3 characters in length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.controls['bankId']?.errors?.pattern && validationForm.get('bankIdType')?.value === 'CNAPS'">
                            CNAPS value may only contain numbers, and must be 12 or 14 characters in length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.controls['bankId']?.errors?.pattern && validationForm.get('bankIdType')?.value === 'CACPA'">
                            Only Bank of Nova Scotia CACPA is allowed. Value may only contain numbers, and must be 9
                            characters in length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.controls['bankId']?.errors?.pattern && validationForm.get('bankIdType')?.value === 'CCI'">
                            CCI value may only contain numbers, and must be 20 characters in length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.controls['bankId']?.errors?.pattern && validationForm.get('bankIdType')?.value === 'CBU'">
                            CBU value may only contain numbers, and must be 22 characters in length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.controls['bankId']?.errors?.pattern && validationForm.get('bankIdType')?.value === 'CVU'">
                            CVU value may only contain numbers, and must be 22 characters in length.
                        </div>
                    </div>

                    <!-- Bank Account Type -->
                    <div class="form-group" *ngIf="bankAccountTypes.length > 1">
                        <label for="bank-account-type">Bank Account Type</label>
                        <select *ngIf="!hideCaret" id="bank-account-type" class="form-control"
                            formControlName="bankAccountType">
                            <option *ngFor="let accountType of bankAccountTypes" [value]="accountType">{{accountType}}
                            </option>
                        </select>
                        <input *ngIf="hideCaret" type="text" id="bank-id-type" class="form-control"
                            formControlName="bankAccountType" [value]="bankAccountTypes[0]" disabled>
                    </div>

                    <div class="form-group bank-id" *ngIf="bankAccountTypes.length === 1">
                        <label for="bank-account-type">Bank Account Type</label>
                        <input type="text" id="bank-id-type" class="form-control" formControlName="bankAccountType"
                            [value]="bankAccountTypes[0]" disabled>
                    </div>

                    <!-- Bank Account Number -->
                    <div class="form-group bank-account-number">
                        <label for="bank-account-number">Bank Account Number
                            <div class="sso-info-tooltip-account-number question-circle-bank-account-number">
                                <span class="tooltip-text info-tooltip"><span class="hover-text">Bank Account
                                        Number or IBAN
                                        <span id="tooltip-tail-bank-account"></span>
                                    </span></span>
                            </div>
                        </label>
                        <input type="text" id="bank-account-number" autocomplete="off" class="form-control"
                            formControlName="bankAccountNumber">
                        <div class="text-danger"
                            *ngIf="validationForm.get('bankAccountNumber')?.errors?.pattern && validationForm.get('bankAccountType')?.value === 'Account Number'">
                            Account Number value may only contain letters, numbers, spaces, period (.) and hyphen (-);
                            and must be between 1 and 34 characters in length.
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.get('bankAccountNumber')?.errors?.pattern && validationForm.get('bankAccountType')?.value === 'CLABE'">
                            CLABE value may only contain numbers, and must be 18 characters in length
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.get('bankAccountNumber')?.errors?.pattern && validationForm.get('bankAccountType')?.value === 'BBAN'">
                            BBAN value may only contain letters and numbers, and must be between 10 and 30 characters in
                            length
                        </div>
                        <div class="text-danger"
                            *ngIf="validationForm.get('bankAccountNumber')?.errors?.pattern && (validationForm.get('bankAccountType')?.value === 'IBAN')">
                            IBAN value may only contain letters, numbers, and hyphens(-); and must be between 5 and 34
                            characters in length
                        </div>
                    </div>

                    <!-- Branch ID -->
                    <div class="form-group branch-id" *ngIf="isBranchIdRequired">
                        <label for="branch-id">Branch ID</label>
                        <input type="text" id="branch-id" class="form-control" autocomplete="off"
                            formControlName="branchId">
                        <div *ngIf="validationForm.get('branchId')?.errors?.pattern"
                            class="text-danger validation-error">
                            Branch ID may only contain numbers and dashes(-), and cannot be more than 5 chars in length.
                        </div>
                    </div>

                    <!-- Benificiary Type -->
                    <div class="form-group full-width" *ngIf="showBeneficiaryType">
                        <label>Beneficiary Type</label>
                        <div class="radio-group">
                            <label>
                                <input type="radio" formControlName="beneficiaryType" name="beneficiaryType"
                                    value="individual"> Individual
                            </label>
                            <label>
                                <input type="radio" formControlName="beneficiaryType" name="beneficiaryType"
                                    value="company"> Company
                            </label>
                        </div>
                    </div>

                    <!-- Beneficiary - Company -->
                    <div class="beneficiary-names form-group full-width">
                        <div class="form-group" *ngIf="checkForBeneficiaryType === 'company' && validationForm.get('country')?.value === 'US'">
                            <label for="beneficiary-first-name">Business Name (Optional)</label>
                            <input type="text" id="beneficiary-first-name" minlength="2"
                                class="form-control" autocomplete="off" formControlName="businessName">
                            <div class="text-danger"
                                *ngIf="validationForm.get('businessName')?.errors?.maxlength">
                                Business Name cannot be more than 150 characters.
                            </div>
                        </div>

                        <div class="form-group" *ngIf="validationForm.get('country')?.value !== 'US'">
                            <label for="beneficiary-first-name">Beneficiary Name (Optional)</label>
                            <input type="text" id="beneficiary-first-name" minlength="2"
                                class="form-control" autocomplete="off" formControlName="businessName">
                            <div class="text-danger"
                                *ngIf="validationForm.get('businessName')?.errors?.maxlength">
                                Beneficiary Name cannot be more than 150 characters.
                            </div>
                        </div>

                        <!-- Tax ID -->
                        <div class="form-group" *ngIf="enableTaxId">
                            <label for="tax-id">Tax ID</label>
                            <input type="text" id="tax-id" placeholder="Enter Tax ID" class="form-control"
                                autocomplete="off" formControlName="taxId" [value]="taxId">
                            <div class="text-danger" *ngIf="validationForm.get('taxId')?.errors?.pattern">
                                Tax ID value must be 18 characters in length.
                            </div>
                        </div>
                    </div>

                    <!-- Beneficiary - Individual -->
                    <div class="beneficiary-names form-group full-width benificary-names-style"
                        *ngIf="checkForBeneficiaryType === 'individual' && validationForm.get('country')?.value === 'US'">
                        <div class="form-group">
                            <label for="beneficiary-first-name">Beneficiary First Name (Optional)</label>
                            <input type="text" id="beneficiary-first-name" class="form-control"
                                autocomplete="off" formControlName="beneficiaryFirstName">
                            <div class="text-danger"
                                *ngIf="validationForm.get('beneficiaryFirstName')?.errors?.maxlength">
                                First Name cannot be more than 35 characters.
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <label for="beneficiary-last-name">Beneficiary Last Name (Optional)</label>
                            <input type="text" id="beneficiary-last-name" class="form-control"
                                autocomplete="off" formControlName="beneficiaryLastName">
                            <div class="text-danger"
                                *ngIf="validationForm.get('beneficiaryLastName')?.errors?.maxlength">
                                Last Name cannot be more than 35 characters.
                            </div>
                        </div>
                    </div>
                    <div *ngIf="validationForm.errors?.minLengthCombined"
                        class="form-group full-width text-danger validation-error">
                        At least 3 characters must be entered combined in the beneficiary name fields.
                    </div>

                    <!-- National ID -->
                    <div class="form-group national-id" *ngIf="showNationalId">
                        <label for="national-id">National ID</label>
                        <input type="text" autocomplete="off" id="national-id" class="form-control"
                            formControlName="nationalId">
                        <div *ngIf="validationForm.get('nationalId')?.errors?.pattern"
                            class="text-danger validation-error">
                            National ID may only contain letters, numbers, forward slashes(/), periods(.), and
                            dashes(-), and cannot be more than 35 chars in length
                        </div>
                    </div>
                    <div class="form-group billing-account full-width" *ngIf="billingAccountList.size > 1">
                        <label for="billing-account">Billing Account</label>
                        <select id="billing-account" class="form-control"
                            formControlName="billingAccount">
                            <option *ngFor="let account of billingAccountList | keyvalue" [value]="account.key">
                                {{account.key | maskAccount}}</option>
                        </select>
                    </div>
                    <div class="form-group billing-account full-width" *ngIf="billingAccountList.size === 1">
                        <label for="billing-account">Billing Account</label>
                        <input type="text" id="billing-account" class="form-control" formControlName="billingAccount"
                            [(ngModel)]="maskedAccountNum" disabled>
                    </div>
                    <div class="form-group terms full-width">
                        <label class="terms-conditions">
                            <input type="checkbox" formControlName="terms" (change)="onTermsClick($event)"
                                [checked]="isChecked"> I agree to the <a href="#" (click)="openTerms(termsContent)">Terms &
                                Conditions</a> and understand the charge of <span class="charge">$1.25</span> per validation. If an account is
                            unable to be found, no
                            charge will be incurred.
                        </label>
                    </div>
                    <div class="form-actions full-width">
                        <a href="" click="onBack()" class="back-button"><span class="chevron"></span>Back</a>
                        <button type="submit" (click)="onSubmit()" class="btn btn-primary cbensightenevent" cbdata-type="button" cbdata-reason="validate-account"
                            [disabled]="!validationForm.valid || validationForm.get('bankIdType')?.value === ''">Validate Account</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- terms & condition -->
<ng-template #termsContent let-modal>
    <div class="modal-header more-notifications">
        <h4 class="modal-title">Citizens Account Validation Service</h4>
        <span class="cbds-c-iconButton remove-popup cbds-c-iconButton--contained cbensightenevent" aria-label="close"
            cbdata-type="button" cbdata-reason="message-modal-cross-close" (click)="closeModal()">
            <svg class="cbds-c-icon close-icon" focusable="false">
                <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"
                    [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"></use>
            </svg>
        </span>
    </div>
    <div class="modal-body">
        <p [innerHtml]="termsAndConditions.general_terms"></p>

        <p [innerHtml]="termsAndConditions.description_of_service"></p>

        <p [innerHtml]="termsAndConditions.access"></p>

        <p [innerHtml]="termsAndConditions.availability"></p>

        <p [innerHtml]="termsAndConditions.inquiry.description"></p>
        <div *ngFor="let requirement of termsAndConditions.inquiry.requirements">
            <p [innerHtml]="requirement.description"></p>
            <ul>
                <li *ngFor="let item of requirement.items" [innerHtml]="item"></li>
            </ul>
        </div>

        <p [innerHtml]="termsAndConditions.responses.description"></p>

        <p class="fw-bold" [innerHtml]="termsAndConditions.scope_of_use.title"></p>

        <div *ngFor="let covenant of termsAndConditions.scope_of_use.covenants">
            <p [innerHtml]="covenant.description"></p>
            <ul>
                <li *ngFor="let item of covenant.items" [innerHtml]="item"></li>
            </ul>
        </div>
    </div>
    <div class="terms">
        <button type="submit" (click)="onContinue()" class="btn btn-primary continue-button">Close</button>
    </div>
</ng-template>

<!-- Pending Results -->
<div *ngIf="validationPendingMsg" class="error-modal" role="dialog" tabindex="-1">
    <div class="error-modal-dialog modal-dialog-centered error-smallModal" role="document">
        <div class="error-modal-content">
            <button class="close-button" (click)="onBackFromPendingModal()">&times;</button>
            <div class="modal-body text-content">
                <p>We apologize, but this request is taking longer than expected. You may close this window and we will
                    continue to attempt to validate this account. <br>
                    You will find the results in the <strong>Validation History </strong>page.</p>
            </div>
            <div class="modal-footer">
                <button (click)="newValidationRequest()" class="btn btn-primary cbensightenevent" type="button"
                    cbdata-type="button" cbdata-reason="validate-another-account">
                    Validate Another Account
                </button>
                <button (click)="onBackFromPendingModal()" class="btn btn-secondary cbensightenevent"
                    style="margin: 0 0 0 0 !important;" type="button" cbdata-type="button" cbdata-reason="back">
                    Back To Dashboard
                </button>
            </div>
        </div>
    </div>
</div>


<!-- Success Results -->
<div *ngIf="!accValidationForm && validatedInfo && !errorModal">
    <div class="success-msg">
        <div class="msg-container">
            <div class="msg-icon">
                <svg *ngIf="validatedInfo?.inquiryStatus !== 'INITIATED'" class="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="18" width="18"
                    viewBox="0 0 48 48" aria-hidden="true" style="margin-right: 10px;margin-top: -1px;">
                    <circle class="circle" fill="rgb(0, 100, 80)" cx="24" cy="24" r="22" />
                    <path class="tick" fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round"
                        stroke-linejoin="round" stroke-miterlimit="10" d="M14 27l5.917 4.917L34 17" />
                </svg>
            </div>
            <div *ngIf="validatedInfo?.inquiryStatus === 'INITIATED'" class="acc-verif-icon icon-clock msg-icon"></div>
            <div class="msg-text">
                {{validationMsg}}
            </div>
        </div>
    </div>

    <div class="form-group success-grid-container">
        <div class="grid-item row1">
            <label for="account-status">Account Status</label>
            <div style="display: grid; grid-template-columns: .2fr 3.2fr; align-items: center;">
                <div class="acc-verif-icon" [ngClass]="'icon-' + (validatedInfo?.accountStatus | lowercase)"></div>
                <div>{{validatedInfo?.accountStatus ? validatedInfo?.accountStatus : (validatedInfo?.inquiryStatus === 'INITIATED' ? 'Verification in process': '') | sentenceCase}}</div>
            </div>
        </div>

        <div class="grid-item row1">
            <label for="account-status">Inquiry Status</label>
            <div class="status-container">
                <div class="status-text">{{validatedInfo?.inquiryStatus | sentenceCase }}</div>
                <span class="sso-tooltip status-icon" *ngIf="validatedInfo?.inquiryStatusMessage">
                    <div class="acc-verif-icon icon-info" style="margin-left: 5px; margin-top: 1px;"></div>
                    <span id="question" class="tooltipText loginTooltip">
                        <span class="hoverText">{{validatedInfo?.inquiryStatusMessage}}
                            <span id="tail">
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div class="grid-item row1">
            <label for="name-match">Name Match</label>
            <div style="display: grid; grid-template-columns: .2fr 3.2fr; align-items: center;">
                <div class="status-container">
                    <div class="acc-verif-icon" [ngClass]="'icon-' + (validatedInfo?.beneficiaryNameMatch | lowercase)">
                    </div>
                    <!-- check if they are sending beneficiaryIndividualName -->
                    <div>{{(trimValue(validatedInfo?.beneficiaryIndividualName) || trimValue(validatedInfo?.businessName)) ? (validatedInfo?.beneficiaryNameMatch ? (validatedInfo?.beneficiaryNameMatch | sentenceCase) : 'N/A') : ''}}</div>
                    <span class="sso-tooltip status-icon" *ngIf="checkBeneficiaryNameMatch === 'N/A'">
                        <div class="acc-verif-icon icon-info icon-align"></div>
                        <span id="question" class="tooltipText loginTooltip">
                            <span class="hoverText">{{naTooltipText}}
                                <span id="tail">
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        </div>

        <!-- only for prevalidated status -->
        <div *ngIf="accAlreadyVerified" class="grid-item row1">
            <label for="date-verified">Date Verified</label>
            <p>{{validatedInfo?.validatedDate ? validatedInfo?.validatedDate : (validatedInfo?.inquiryStatus === 'INITIATED' ? validatedInfo?.createdDate : '') | date: 'MM/dd/yyyy'}}</p>
        </div>

        <!-- only for prevalidated status -->
        <div *ngIf="accAlreadyVerified" class="grid-item row1">
            <label for="name-match">Inquiry by</label>
            <p>{{validatedInfo?.userProfile?.firstName}} {{validatedInfo?.userProfile?.lastName}}</p>
        </div>

        <div class="grid-item row2">
            <label for="country-ofaccount">Country of Account</label>
            <p>{{validatedInfo?.bankAccountCountryName}}</p>
        </div>
        <div class="grid-item row3">
            <label for="bank-id">Bank ID</label>
            <p>{{validatedInfo?.bankId}}</p>
        </div>
        <div class="grid-item row4">
            <label for="bank-account-type">Bank Account Type</label>
            <p>{{validatedInfo?.bankAccountType}}</p>
        </div>
        <div class="grid-item row4">
            <label for="bank-account-number">Bank Account Number</label>
            <p>{{validatedInfo?.bankAccountNumber}}</p>
        </div>
        <div class="grid-item row5" *ngIf="validatedInfo?.bankAccountCountryName === 'US'">
            <label for="beneficiary-type">Beneficiary Type</label>
            <p>{{validatedInfo?.beneficiaryType}}</p>
        </div>
        <div class="grid-item row6" *ngIf="validatedInfo?.beneficiaryType === 'Individual' && validatedInfo?.bankAccountCountryName === 'US'">
            <label for="beneficiary-name">Beneficiary First Name</label>
            <p>{{validatedInfo?.beneficiaryFirstName}}</p>
        </div>
        <div class="grid-item row6" *ngIf="validatedInfo?.beneficiaryType === 'Individual' && validatedInfo?.bankAccountCountryName === 'US'">
            <label for="beneficiary-last-name">Beneficiary Last Name</label>
            <p>{{validatedInfo?.beneficiaryLastName}}</p>
        </div>
        <div class="grid-item row6" *ngIf="validatedInfo?.beneficiaryType === 'Company' && validatedInfo?.bankAccountCountryName === 'US'">
            <label for="beneficiary-last-name">Business Name</label>
            <p>{{validatedInfo?.businessName}}</p>
        </div>
        <div class="grid-item row5" *ngIf="validatedInfo?.bankAccountCountryName !== 'US'">
            <label for="beneficiary-last-name">Beneficiary Name</label>
            <p>{{validatedInfo?.businessName}}</p>
        </div>
        <div class="grid-item row7">
            <label for="billing-account">Billing Account</label>
            <p>{{validatedInfo?.billingAccountNumber}}</p>
        </div>

        <div class="form-actions full-width">
            <a href="" click="onBack()" class="back-button"><span class="chevron"></span>Back</a>
            <button type="submit" (click)="newValidationRequest()"
                class="btn btn-danger">Validate Another Account</button>
            <button *ngIf="accAlreadyVerified" type="submit" (click)="revalidateRequest()"
                class="btn btn-danger">Revalidate</button>
        </div>
    </div>
</div>

<!-- Error Results -->
<div *ngIf="errorModal" class="error-modal" role="dialog" tabindex="-1">
    <div class="error-modal-dialog modal-dialog-centered error-smallModal" role="document">
        <div class="error-modal-content">
            <button class="close-button" (click)="onBackFromPendingModal()">&times;</button>
            <div class="modal-body text-content">
                <div *ngIf="validatedInfo?.inquiryStatus === 'INITIATED'" class="acc-verif-icon icon-clock msg-icon"></div>
               <p  style="color:rgb(188, 42, 42)" [innerHTML]="validationMsg"></p>

                <div>
                </div>
                <div class="modal-footer">
                    <button (click)="onBackFromPendingModal()" class="btn btn-secondary cbensightenevent"
                        style="margin: 0 0 0 0 !important;" type="button" cbdata-type="button" cbdata-reason="back">
                        Back To Dashboard
                    </button>
            </div>
        </div>
    </div>
</div>