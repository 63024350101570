import { Component, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verification-view',
  templateUrl: './verification-view.component.html',
  styleUrls: ['./verification-view.component.scss']
})
export class VerificationViewComponent {
  
  selectedTab: string;
  @Input() isHistoryDisabled: any;
  public header: any = environment.header;

  reloadHistory: boolean = false;

  constructor(
    private service: ApiService,
    private modal: NgbModal) {
  }

  ngOnInit(): void {
    this.service.currentTab$.subscribe((tab: string) => {
      this.selectedTab = tab;
    })
  }

  onHistoryTabClick() {
      this.selectedTab = 'history';
      if (this.selectedTab === 'history') {
        this.reloadHistory = !this.reloadHistory;
      }
  }

  onBack(): void {
    this.modal.dismissAll()
  }

}
