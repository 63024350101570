<div *ngIf="!isMobileDevice && showValidateAccountAction">
    <div class="action">
    <div class="accverif-card accverif-validation-width acct-verif-vertical-card" *ngIf="showValidateAccountAction">
        <div class="accverif-card-body" >
            <div class="header-area">
                <div class="award-checkmark"></div>
                <button class="cbensightenevent" cbdata-type="button" cbdata-reason="validate-account" (click)="openModal('validation')">Validate an Account</button>
            </div>
            <p class="para-text">Confirm bank account status and beneficial ownership of a bank account.
            </p>

        </div>
    </div>
    <div class="accverif-card accverif-history-width acct-verif-vertical-card" *ngIf="showValidateAccountAction">
        <div class="accverif-card-body">
            <div class="header-area">
                <div class="award-checkmark "></div>
                <button class="cbensightenevent" cbdata-type="button" cbdata-reason="view-validate-history" (click)="openModal('history')">View Validation History</button>
            </div>
            <p class="para-text">View the history of your account validation requests.</p>
        </div>
    </div>
</div>
</div>